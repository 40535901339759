import revive_payload_client_4AiwatoVjb from "/builds/mingat/mingat.com/frontend/node_modules/.pnpm/nuxt@3.13.2_eslint@9.12.0_rollup@4.24.0_sass@1.79.5_stylelint@16.9.0_typescript@5.5.4_vite@5.4.8/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_lpOTWvOERJ from "/builds/mingat/mingat.com/frontend/node_modules/.pnpm/nuxt@3.13.2_eslint@9.12.0_rollup@4.24.0_sass@1.79.5_stylelint@16.9.0_typescript@5.5.4_vite@5.4.8/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_W6ahOuB0Fb from "/builds/mingat/mingat.com/frontend/node_modules/.pnpm/nuxt@3.13.2_eslint@9.12.0_rollup@4.24.0_sass@1.79.5_stylelint@16.9.0_typescript@5.5.4_vite@5.4.8/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MfU0D3dslB from "/builds/mingat/mingat.com/frontend/node_modules/.pnpm/nuxt-site-config@2.2.18_rollup@4.24.0_vite@5.4.8_vue@3.5.11/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_sk7rhP05st from "/builds/mingat/mingat.com/frontend/node_modules/.pnpm/nuxt@3.13.2_eslint@9.12.0_rollup@4.24.0_sass@1.79.5_stylelint@16.9.0_typescript@5.5.4_vite@5.4.8/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_jrpM5mZuCc from "/builds/mingat/mingat.com/frontend/node_modules/.pnpm/nuxt@3.13.2_eslint@9.12.0_rollup@4.24.0_sass@1.79.5_stylelint@16.9.0_typescript@5.5.4_vite@5.4.8/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_07syIwlAyC from "/builds/mingat/mingat.com/frontend/node_modules/.pnpm/nuxt@3.13.2_eslint@9.12.0_rollup@4.24.0_sass@1.79.5_stylelint@16.9.0_typescript@5.5.4_vite@5.4.8/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_bNt3A1koyY from "/builds/mingat/mingat.com/frontend/node_modules/.pnpm/nuxt@3.13.2_eslint@9.12.0_rollup@4.24.0_sass@1.79.5_stylelint@16.9.0_typescript@5.5.4_vite@5.4.8/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/builds/mingat/mingat.com/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_RjBu2AJEea from "/builds/mingat/mingat.com/frontend/node_modules/.pnpm/nuxt@3.13.2_eslint@9.12.0_rollup@4.24.0_sass@1.79.5_stylelint@16.9.0_typescript@5.5.4_vite@5.4.8/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_sNOb11NZEO from "/builds/mingat/mingat.com/frontend/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_nuxt@3.13.2_rollup@4.24.0_vue@3.5.11/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_client_ZHlGte4eJr from "/builds/mingat/mingat.com/frontend/node_modules/.pnpm/@nuxtjs+plausible@0.2.4_rollup@4.24.0/node_modules/@nuxtjs/plausible/dist/runtime/plugin.client.mjs";
import switch_locale_path_ssr_uQxXoWhtBq from "/builds/mingat/mingat.com/frontend/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_rollup@4.24.0_vue@3.5.11/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_0T4Bi5RaJ6 from "/builds/mingat/mingat.com/frontend/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_rollup@4.24.0_vue@3.5.11/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import _00_debug_CzQoA0myfc from "/builds/mingat/mingat.com/frontend/plugins/00.debug.ts";
import _01_sentry_client_2rh1pDAIty from "/builds/mingat/mingat.com/frontend/plugins/01.sentry.client.ts";
import _10_mingat_api_WlYmeaB8p8 from "/builds/mingat/mingat.com/frontend/plugins/10.mingat-api.ts";
import _11_url_resolver_4Yu8OnWGIl from "/builds/mingat/mingat.com/frontend/plugins/11.url-resolver.ts";
export default [
  revive_payload_client_4AiwatoVjb,
  unhead_lpOTWvOERJ,
  router_W6ahOuB0Fb,
  _0_siteConfig_MfU0D3dslB,
  payload_client_sk7rhP05st,
  navigation_repaint_client_jrpM5mZuCc,
  check_outdated_build_client_07syIwlAyC,
  chunk_reload_client_bNt3A1koyY,
  components_plugin_KR1HBZs4kY,
  prefetch_client_RjBu2AJEea,
  plugin_sNOb11NZEO,
  plugin_client_ZHlGte4eJr,
  switch_locale_path_ssr_uQxXoWhtBq,
  i18n_0T4Bi5RaJ6,
  _00_debug_CzQoA0myfc,
  _01_sentry_client_2rh1pDAIty,
  _10_mingat_api_WlYmeaB8p8,
  _11_url_resolver_4Yu8OnWGIl
]