<script setup lang="ts">
import type { ContentBlock } from '~/types/api'
import type { IpxImage } from '~/types/app'

type PageError = {
    error: {
        url: string
        statusCode: number
        statusMessage: string
        message: string
        description: string
        data: unknown
    }
}
const { t } = useI18n()
const props = defineProps<PageError>()

const othersBlocks = computed<Array<ContentBlock> | undefined>(() => {
    return useContentBlocksState().value?.filter((block: ContentBlock) => block.type !== 'hero_header')
})

const errorTitle = computed(() => {
    if (props.error.message.match(/<NO RESPONSE>/gi)) {
        return t('API is not available')
    }

    return props.error.message.startsWith('Page not found:') || props.error.statusCode === 404
        ? t('Not Found')
        : t(props.error.message)
})

const errorLabel = computed(() => {
    return t('error_number', { statusCode: props.error.statusCode })
})

const { metaClient } = usePageSeoMeta(null, { title: errorTitle.value, noIndex: true })

useSeoMeta(metaClient)

const ipxImage: IpxImage = {
    relativePath: '/error-banner.jpg',
    alt: `IBannière page ${props.error.statusCode}}`,
}
</script>

<template>
    <NuxtLayout
        name="default"
        with-reservation-form
        offset-reservation-form
    >
        <template #header>
            <VHeader
                :breadcrumb-items="[{ label: errorLabel }]"
                :title="errorTitle"
                :class="$style.header"
                :ipx-image="ipxImage"
                layout="default"
            />
        </template>
        <template #default>
            <main
                v-if="othersBlocks?.length"
                class="main-content-width"
            >
                <VContentBlockFactory :content-blocks="othersBlocks" />
            </main>
        </template>
    </NuxtLayout>
</template>

<style lang="scss" module>
.header {
    --v-header-body-padding-bottom: calc(var(--v-reservation-offset-top) * -1 + #{rem(54)});
}

.picture {
    width: 100%;
    height: 100%;
    min-height: var(--v-header-image-min-height, rem(350));

    img,
    iframe {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}
</style>
